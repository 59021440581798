<template>
  <div class="edit-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.meetingName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/contacts/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } }">联系人管理</el-breadcrumb-item>
      <el-breadcrumb-item>修改联系人</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-form :model="editForm" :rules="editRules" ref="editRef" class="edit-form" label-position="left" label-width="100px">
        <el-form-item label="排序" prop="sort">
          <el-input-number size="medium" v-model="editForm.sort" :min="0" :step-strictly="true"></el-input-number>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="头像" prop="avatar">
          <upload :url="editForm.avatar" :filed="'avatar'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="微信二维码" prop="ewm">
          <upload :url="editForm.ewm" :filed="'ewm'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
        </el-form-item>
        <el-form-item label="联系邮箱" prop="mail">
          <el-input v-model="editForm.mail"></el-input>
        </el-form-item>
        <el-form-item label="职责" prop="duty">
          <el-input v-model="editForm.duty"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="mark">
          <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6}" placeholder="请输入备注内容" v-model="editForm.mark"></el-input>
        </el-form-item>
        <el-form-item label="是否需要权限" prop="is_auth">
          <el-radio-group v-model="editForm.is_auth">
            <el-radio :label="1">需要</el-radio>
            <el-radio :label="2">不需要</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancleForm">取消添加</el-button>
          <el-button type="primary" @click="submitForm('editRef')">确认修改</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Upload from '../../../../common/Upload'
export default {
  name: 'Edit',
  components: {
    Upload
  },
  data () {
    return {
      editForm: {
        mId: this.$route.params.mId,
        sort: 0,
        name: '',
        phone: '',
        avatar: '',
        ewm: '',
        mail: '',
        duty: '',
        mark: '',
        is_auth: 2
      },
      editRules: {
        name: [
          { required: true, message: '请填写联系人姓名', trigger: 'blur' },
          { min: 2, max: 12, message: '长度在 2 到 12 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 获取被修改信息
    async getInfo () {
      const { data: res } = await this.$http.get('/shell-contacts/' + this.$route.params.id)
      if (res.status === 200) {
        this.editForm.sort = res.data.sort
        this.editForm.name = res.data.name
        this.editForm.phone = res.data.phone
        this.editForm.avatar = res.data.avatar
        this.editForm.ewm = res.data.ewm
        this.editForm.mail = res.data.mail
        this.editForm.duty = res.data.duty
        this.editForm.mark = res.data.mark
        this.editForm.is_auth = Number(res.data.is_auth)
      } else {
        this.$message.error(res.msg)
      }
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.editForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.editForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.editForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.editForm[field].splice(checkedIndex, 1)
      }
    },
    // 添加表单
    submitForm (formname) {
      this.$refs.editRef.validate(async (valid, item) => {
        if (valid) {
          const { data: res } = await this.$http.put('/shell-contacts/' + this.$route.params.id, this.editForm)
          if (res.status === 200) {
            this.$message.success(res.msg)
            this.$refs.editRef.resetFields()
            await this.$router.push({ path: '/admin/apps/shell/content/contacts/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
          }
        } else {
          this.$message.error('请检查表单必填项')
          return false
        }
      })
    },
    // 取消添加
    cancleForm () {
      this.$router.push({ path: '/admin/apps/shell/content/contacts/' + this.$route.params.mId, query: { itemName: this.$route.query.meetingName } })
    }
  }
}
</script>

<style scoped>
.edit-form{
  width: 650px;
  margin-left: auto;
  margin-right: auto;
}
</style>
